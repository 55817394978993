import React from "react";
import { Checkbox, Pane, Text } from "evergreen-ui";

export default function Goal({ index, content, todayGoals, todayGoalsSet }) {
  const toggleGoal = () => {
    const toggled = [...todayGoals];
    toggled[index].completed = !toggled[index].completed;
    todayGoalsSet(toggled);
  };

  return (
    <Pane justifyContent="space-between" onClick={() => toggleGoal()} cursor="pointer" display={!content ? "none" : "flex"}>
      <Text userSelect="none">
        Goal {index + 1}: {content}
      </Text>
      <Checkbox margin="0" checked={todayGoals[index].completed} onClick={(e) => e.preventDefault()} />
    </Pane>
  );
}
