import { database } from "../firebaseConfig";
import { ref, set } from "firebase/database";

export function writeCheckinData(userId, date, checkinData) {
  // write checkin data
  set(ref(database, `checkins/${userId}/${date}`), {
    ...checkinData,
  });
  // update last checkin date
  set(ref(database, `users/${userId}/lastCheckinDate`), date);
  // update checkin history
  set(ref(database, `users/${userId}/checkinHistory/${date}`), true);
}
