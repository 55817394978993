import React from "react";
import { Button, Pane, Text, TextInput, majorScale } from "evergreen-ui";
import { cloneDeep } from "lodash";

export default function GoalInput({ index, tomorrowGoals, tomorrowGoalsSet }) {
  const setGoals = (value) => {
    const goals = [...tomorrowGoals];
    goals[index] = value;
    tomorrowGoalsSet(goals);
  };

  const removeGoal = () => {
    const newGoals = cloneDeep(tomorrowGoals);
    newGoals.splice(index, 1);
    tomorrowGoalsSet(newGoals);
  };

  return (
    <Pane display="flex" flexDirection="row" alignItems="center" padding={majorScale(1)}>
      <Text paddingRight="2px">Goal {index + 1}: </Text>
      <TextInput flexBasis="90%" value={tomorrowGoals[index]} onChange={(e) => setGoals(e.target.value)} />
      <Button border="none" padding="0" backgroundColor="gray100" onClick={() => removeGoal()}>
        x
      </Button>
    </Pane>
  );
}
