import { database } from "../firebaseConfig";
import { ref, child, get } from "firebase/database";

export async function readCheckin(userId, date) {
  const snapshot = await get(child(ref(database), `checkins/${userId}/${date}`));
  try {
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
    }
  } catch (e) {
    console.error(e);
  }
}
