import React from "react";
import { Button, Pane, TextInput, minorScale } from "evergreen-ui";
import { cloneDeep } from "lodash";

export default function HabitInput({ index, habitList, habitListSet }) {
  const updateHabits = (value) => {
    const newHabits = cloneDeep(habitList);
    newHabits[index] = value;
    habitListSet(newHabits);
  };

  const removeHabit = () => {
    const newHabits = cloneDeep(habitList);
    newHabits.splice(index, 1);
    habitListSet(newHabits);
  };

  return (
    <Pane display="flex" flexDirection="row" justifyContent="left" paddingY={minorScale(1)}>
      <TextInput value={habitList[index]} onChange={(e) => updateHabits(e.target.value)} />
      <Button border="none" padding="0" backgroundColor="gray100" onClick={() => removeHabit()}>
        x
      </Button>
    </Pane>
  );
}
