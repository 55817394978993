import React, { useState } from "react";
import { auth } from "../firebaseConfig";
import { signInWithPopup, createUserWithEmailAndPassword, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { ThemeProvider, defaultTheme, Pane, Card, Button } from "evergreen-ui";
import { useNavigate } from "react-router-dom";

function App() {
  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");

  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const loginUser = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });
  };

  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      navigate("/home");
    } else {
    }
  });

  return (
    <ThemeProvider value={defaultTheme}>
      <Pane id="login-page">
        <Card>
          <Button onClick={() => googleSignIn()}>Sign-in with Google</Button>
        </Card>
      </Pane>
    </ThemeProvider>
  );
}

export default App;
