import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebaseConfig.js";
import { theme } from "../App.js";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { ThemeProvider, Pane, Heading, Textarea, Card, majorScale, Strong, Button, toaster } from "evergreen-ui";
import Goal from "./Goal.js";
import GoalInput from "./GoalInput.js";
import Habit from "./Habit.js";
import { writeCheckinData } from "../functions/writeCheckinData.js";
import { readCheckin } from "../functions/readCheckin.js";
import { syncCheckinData } from "../functions/syncCheckinData.js";
import PendingCheckinOverlay from "./PendingCheckinOverlay.js";
import { getPrevDayDateString, getWeekdayMonthDateString, isYesterday, validateHabits, validateTomorrowGoals } from "../helpers.js";
import { UserContext } from "../UserContext.js";

// WHY IS IT RENDERING 8 TIMES?!
// // // // Make this the default, as in don't look back at the last one, but then have a weekend mode that combines Saturday and Sunday
// TODO: remove the urlParam of today's date - I don't think people will want to pop back in time and if they do it won't be via URL it'll be through the mosaic calendar & scrolling presumably!
// TODO: validate, pickle/minimize the submitted & pending data
// TODO: separate component for checkinHistory vs a currentCheckin - !!!
// // // // can have a list of those mapped/scrollable-to generated in the checkin route

function Checkin() {
  const navigate = useNavigate();
  const urlParams = useParams();
  // meta-state
  const [loading, loadingSet] = useState(true);
  const [isPastCheckin, isPastCheckinSet] = useState(false);
  const [prevPendingCheckin, prevPendingCheckinSet] = useState(null);
  // checkin state
  const [todayGoals, todayGoalsSet] = useState();
  const [habits, habitsSet] = useState();
  const [checkin, checkinSet] = useState();
  const [tomorrowGoals, tomorrowGoalsSet] = useState();
  // sync state
  const userSettings = useContext(UserContext);

  useEffect(() => {
    if (loading) checkinSetup();
  }, [userSettings]);

  useEffect(() => {
    if (loading || isPastCheckin || prevPendingCheckin) return;
    // TODO: check if empty,
    console.log("todayGoals", todayGoals);
    console.log("habits", habits);
    console.log("checkin", checkin);
    console.log("tomorrowGoals", tomorrowGoals);

    const debounceSync = setTimeout(() => {
      syncCheckinData(auth.currentUser.uid, {
        date: urlParams.date,
        state: {
          todayGoals,
          habits: validateHabits(habits),
          checkin,
          tomorrowGoals: validateTomorrowGoals(tomorrowGoals),
        },
      });
    }, 1500);
    return () => clearTimeout(debounceSync);
  }, [todayGoals, habits, checkin, tomorrowGoals]);

  const setupNewCheckin = async (userSettings) => {
    const yesterdaysDate = getPrevDayDateString(urlParams.date);
    let lastCheckinData;
    if (Object.keys(userSettings?.checkinHistory).includes(yesterdaysDate)) {
      lastCheckinData = await readCheckin(auth.currentUser.uid, yesterdaysDate);
    }
    const habits = userSettings?.currentHabits;
    todayGoalsSet(
      lastCheckinData
        ? lastCheckinData.tomorrowGoals.map((goal) => {
            return { task: goal, completed: false };
          })
        : []
    );
    habitsSet(
      userSettings?.currentHabits
        ? {
            night: habits?.night?.reduce((list, habit) => {
              list.push({ task: habit.task, completed: false });
              return list;
            }, []),
            morning: habits?.morning?.reduce((list, habit) => {
              list.push({ task: habit.task, completed: false });
              return list;
            }, []),
            day: habits?.day?.reduce((list, habit) => {
              list.push({ task: habit.task, completed: false });
              return list;
            }, []),
          }
        : { day: [], morning: [], night: [] }
    );
    checkinSet("");
    tomorrowGoalsSet(["", "", ""]);
    loadingSet(false);
  };

  const setupCheckinFromHistory = async () => {
    const checkinData = await readCheckin(auth.currentUser.uid, urlParams.date);
    const goals = checkinData?.todayGoals ?? [];
    const habits = checkinData.habits;
    todayGoalsSet(
      goals.map((goal) => {
        return { task: goal.task, completed: goal.completed };
      })
    );
    habitsSet({
      night: habits?.night?.reduce((list, habit) => {
        list.push({ task: habit.task, completed: habit.completed });
        return list;
      }, []),
      morning: habits?.morning?.reduce((list, habit) => {
        list.push({ task: habit.task, completed: habit.completed });
        return list;
      }, []),
      day: habits?.day?.reduce((list, habit) => {
        list.push({ task: habit.task, completed: habit.completed });
        return list;
      }, []),
    });
    isPastCheckinSet(true);
    checkinSet(checkinData.checkin);
    tomorrowGoalsSet([]);
    loadingSet(false);
  };

  const setupCheckinFromPending = (userSettings) => {
    const pendingCheckin = userSettings.pendingCheckin.state;
    todayGoalsSet(pendingCheckin?.todayGoals ?? []);
    habitsSet({
      night: pendingCheckin?.habits?.night ?? userSettings?.currentHabits?.night ?? [],
      morning: pendingCheckin?.habits?.morning ?? userSettings?.currentHabits?.morning ?? [],
      day: pendingCheckin?.habits?.day ?? userSettings?.currentHabits?.day ?? [],
    });
    checkinSet(pendingCheckin?.checkin ?? "");
    tomorrowGoalsSet(pendingCheckin?.tomorrowGoals ?? []);
    loadingSet(false);
  };

  async function checkinSetup() {
    if (!userSettings) return;

    // there is already a checkin for this date
    if (userSettings && userSettings.checkinHistory && Object.keys(userSettings?.checkinHistory).includes(urlParams.date)) {
      setupCheckinFromHistory();
    }
    // loading from pending checkin state
    else if (userSettings && userSettings.pendingCheckin && userSettings.pendingCheckin.date === urlParams.date) {
      setupCheckinFromPending(userSettings);
    }
    // uncompleted checkin in the past - ask if they want to complete it (try and ensure just 1 pending at a time)
    else if (userSettings && userSettings.pendingCheckin && userSettings.pendingCheckin.date !== urlParams.date) {
      prevPendingCheckinSet(userSettings.pendingCheckin);
      setupNewCheckin(userSettings);
    }
    // a fresh checkin for the night
    else {
      setupNewCheckin(userSettings);
    }
  }

  const submitCheckin = () => {
    toaster.success("Today's Check-in submitted! See you tomorrow.");
    // delete empty object items to not save 'em to DB & not have blank stuff show the next day
    const validatedHabits = validateHabits(habits);
    const validatedTomorrowGoals = validateTomorrowGoals(tomorrowGoals);
    syncCheckinData(auth.currentUser.uid, null);
    writeCheckinData(auth.currentUser.uid, urlParams.date, {
      todayGoals,
      habits: validatedHabits,
      checkin,
      tomorrowGoals: validatedTomorrowGoals,
    });
    setTimeout(() => {
      navigate("/home");
    }, 5000);
  };

  const deletePrevCheckin = () => {
    syncCheckinData(auth.currentUser.uid, null);
    prevPendingCheckinSet(null);
  };

  const savePrevCheckin = (prevCheckin) => {
    const validatedHabits = validateHabits(prevCheckin.state.habits);
    const validatedTomorrowGoals = validateTomorrowGoals(prevCheckin.state.tomorrowGoals);
    if (isYesterday(urlParams.date, prevCheckin.date)) {
      todayGoalsSet(
        prevCheckin.state.tomorrowGoals
          ? prevCheckin.state.tomorrowGoals.map((goal) => {
              return { task: goal, completed: false };
            })
          : []
      );
    }
    writeCheckinData(auth.currentUser.uid, prevCheckin.date, {
      todayGoals,
      habits: validatedHabits,
      checkin,
      tomorrowGoals: validatedTomorrowGoals,
    });
    syncCheckinData(auth.currentUser.uid, null);
    prevPendingCheckinSet(null);
  };

  const noHabits = () => {
    let noHabits = true;
    for (let time in habits) {
      if (habits[time]?.length) noHabits = false;
    }
    return noHabits;
  };

  const weekdayMonthDate = getWeekdayMonthDateString(urlParams.date);

  if (loading) return <></>;
  return (
    <ThemeProvider value={theme}>
      <PendingCheckinOverlay prevPendingCheckin={prevPendingCheckin} deletePrevCheckin={deletePrevCheckin} savePrevCheckin={savePrevCheckin} />
      <Pane padding={majorScale(2)} display="flex" flexDirection="column" justifyContent="center" maxWidth="1080px" marginX="auto">
        <Heading marginX="auto">Check in for {weekdayMonthDate}</Heading>
        <Card appearance="goals" background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)} display={!Object.keys(todayGoals).length ? "none" : ""}>
          <Heading>Today's Goals</Heading>
          {todayGoals.map((goal, i) => (
            <Goal key={`g${i}`} index={i} content={goal.task} todayGoals={todayGoals} todayGoalsSet={todayGoalsSet} />
          ))}
        </Card>

        <Card background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)}>
          <Heading>Habits</Heading>
          {Object.keys(habits).map((time, i) => (
            <Pane key={i} display={noHabits() ? "none" : ""}>
              <Strong>{time}</Strong>
              <Pane display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))">
                {habits[time]?.map((habit, i) => (
                  <Habit key={`${time}-h-${i}`} index={i} time={time} content={habit.task} habits={habits} habitsSet={habitsSet} />
                ))}
              </Pane>
            </Pane>
          ))}
          <Pane display={noHabits() ? "" : "none"}>
            <Button className="navbar-item">
              <NavLink to="/habits">Add Habits!</NavLink>
            </Button>
          </Pane>
        </Card>

        <Card background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)}>
          <Heading>Check-in</Heading>
          <Textarea
            placeholder="How did your day go? What moments do you want to capture? Your job here is to feel your feelings."
            value={checkin}
            onChange={(e) => checkinSet(e.target.value)}
          />
        </Card>

        <Card background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)} display={isPastCheckin ? "none" : ""}>
          <Heading>Tomorrow's Goals</Heading>
          {tomorrowGoals.map((value, i) => (
            <GoalInput key={i} index={i} tomorrowGoals={tomorrowGoals} tomorrowGoalsSet={tomorrowGoalsSet} />
          ))}
          <Button marginX="auto" onClick={() => tomorrowGoalsSet([...tomorrowGoals, ""])}>
            Add Goal
          </Button>
        </Card>
        <Button appearance="submit" marginX="auto" onClick={() => submitCheckin()} display={isPastCheckin ? "none" : ""}>
          Submit Check-in!
        </Button>
      </Pane>
    </ThemeProvider>
  );
}

export default Checkin;
