import React, { useState } from "react";
import { auth } from "../firebaseConfig";
import { theme } from "../App";
import { Button, Card, Heading, Pane, ThemeProvider, majorScale, toaster } from "evergreen-ui";
import { updateCurrentHabits } from "../functions/updateCurrentHabits";
import HabitInput from "../components/HabitInput";
import { onAuthStateChanged } from "firebase/auth";
import { getUserSettings } from "../functions/getUserSettings";

export default function HabitSetup() {
  const [nightHabits, nightHabitsSet] = useState(["", ""]);
  const [morningHabits, morningHabitsSet] = useState(["", ""]);
  const [dayHabits, dayHabitsSet] = useState(["", ""]);
  const [habitsLoaded, habitsLoadedSet] = useState(false);

  onAuthStateChanged(auth, async (user) => {
    if (user && !habitsLoaded) {
      const userSettings = await getUserSettings(auth.currentUser.uid);
      if (!userSettings?.currentHabits) return;
      const currentHabits = userSettings.currentHabits;
      nightHabitsSet(currentHabits.night ? currentHabits.night.map((habit) => habit.task) : ["", ""]);
      morningHabitsSet(currentHabits.morning ? currentHabits.morning.map((habit) => habit.task) : ["", ""]);
      dayHabitsSet(currentHabits.day ? currentHabits.day.map((habit) => habit.task) : ["", ""]);
      habitsLoadedSet(true);
    } else {
    }
  });

  const submitCurrentHabits = () => {
    const currentHabits = {
      night: nightHabits.reduce((list, habit) => {
        if (habit) list.push({ task: habit });
        return list;
      }, []),
      morning: morningHabits.reduce((list, habit) => {
        if (habit) list.push({ task: habit });
        return list;
      }, []),
      day: dayHabits.reduce((list, habit) => {
        if (habit) list.push({ task: habit });
        return list;
      }, []),
    };
    updateCurrentHabits(auth.currentUser.uid, currentHabits);
    toaster.success("Daily habits updated!");
  };

  return (
    <ThemeProvider value={theme}>
      <Pane padding={majorScale(2)} display="flex" flexDirection="column" justifyContent="center" maxWidth="1080px" marginX="auto" paddingTop="5vh">
        <Heading>Habit Setup</Heading>
        <Card background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)}>
          <Heading>Night</Heading>
          <Pane display="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" padding={majorScale(1)}>
            {nightHabits.map((habit, i) => (
              <HabitInput key={`hsn${i}`} index={i} habitList={nightHabits} habitListSet={nightHabitsSet} />
            ))}
          </Pane>
          <Button onClick={() => nightHabitsSet([...nightHabits, ""])}>Add another Habit</Button>
        </Card>
        <Card background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)}>
          <Heading>Morning</Heading>
          <Pane display="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" padding={majorScale(1)}>
            {morningHabits.map((habit, i) => (
              <HabitInput key={`hsm${i}`} index={i} habitList={morningHabits} habitListSet={morningHabitsSet} />
            ))}
          </Pane>
          <Button onClick={() => morningHabitsSet([...morningHabits, ""])}>Add another Habit</Button>
        </Card>
        <Card background="gray100" elevation={1} padding={majorScale(1)} margin={majorScale(1)}>
          <Heading>Day</Heading>
          <Pane display="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" padding={majorScale(1)}>
            {dayHabits.map((habit, i) => (
              <HabitInput key={`hsd${i}`} index={i} habitList={dayHabits} habitListSet={dayHabitsSet} />
            ))}
          </Pane>
          <Button onClick={() => dayHabitsSet([...dayHabits, ""])}>Add another Habit</Button>
        </Card>
        <Button appearance="submit" marginX="auto" onClick={() => submitCurrentHabits()}>
          Update Habits
        </Button>
      </Pane>
    </ThemeProvider>
  );
}
