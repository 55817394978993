import React, { useRef, useState } from "react";
import { ThemeProvider, defaultTheme, mergeTheme } from "evergreen-ui";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; // https://reactrouter.com/en/main/start/tutorial#the-contact-route-ui
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import Checkin from "./routes/checkin.js";
import Home from "./routes/home.js";
import Login from "./routes/login.jsx";
import Logout from "./routes/logout.jsx";
import HabitSetup from "./routes/habitSetup.jsx";
import { UserContext } from "./UserContext.js";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig.js";
import { getUserSettings } from "./functions/getUserSettings.js";

// this will be the thing that has all the layout apparently

// maybe this is where auth gets checked, theres some general loading that happens and then whatever needs to render gets rendered

export const theme = mergeTheme(defaultTheme, {
  components: {
    Button: {
      appearances: {
        submit: {
          color: "white",
          backgroundColor: "#52BD95",
          selectors: {
            _hover: {
              backgroundColor: "#429777",
            },
            _active: {
              backgroundColor: "#317159",
            },
          },
        },
        reject: {
          color: "white",
          backgroundColor: "indianred",
          selectors: {
            _hover: {
              backgroundColor: "firebrick",
            },
            _active: {
              backgroundColor: "darkred",
            },
          },
        },
      },
    },
  },
});

function App() {
  const userSettings = useRef();
  const [loading, loadingSet] = useState(true);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/checkin/:date",
          element: <Checkin />,
        },
        {
          path: "/habits",
          element: <HabitSetup />,
        },
      ],
    },
  ]);

  onAuthStateChanged(auth, async (user) => {
    if (!loading) return;
    const settings = await getUserSettings(user.uid);
    userSettings.current = settings;
    loadingSet(false);
  });

  return (
    <React.StrictMode>
      <ThemeProvider value={defaultTheme}>
        <UserContext.Provider value={userSettings.current}>
          <RouterProvider router={router} />
        </UserContext.Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
