import React from "react";
import { Checkbox, Pane, Text } from "evergreen-ui";
import { cloneDeep } from "lodash";

export default function Habit({ index, time, content, habits, habitsSet }) {
  const toggleHabit = () => {
    const newHabits = cloneDeep(habits);
    newHabits[time][index].completed = !newHabits[time][index].completed;
    habitsSet(newHabits);
  };

  return (
    <Pane display="flex" justifyContent="space-between" maxWidth="200px" onClick={() => toggleHabit()} cursor="pointer">
      <Text userSelect="none">{content}</Text>
      <Checkbox margin="0" checked={habits[time][index].completed} onClick={(e) => e.preventDefault()} />
    </Pane>
  );
}
