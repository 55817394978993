import React, { useRef } from "react";
import { Button, Card, Heading, Overlay, Pane, Text, ThemeProvider, majorScale } from "evergreen-ui";
import { theme } from "../App";
import { getWeekdayMonthDateString } from "../helpers";

export default function PendingCheckinOverlay({ prevPendingCheckin, deletePrevCheckin, savePrevCheckin }) {
  const weekdayMonthDate = getWeekdayMonthDateString(prevPendingCheckin?.date);
  let headerText = useRef(`Your check in from ${weekdayMonthDate} wasn't submitted.`);

  return (
    <ThemeProvider value={theme}>
      <Overlay isShown={!!prevPendingCheckin} shouldCloseOnClick={false} shouldCloseOnEscapePress={false}>
        <Card background="gray100" elevation={1} position="absolute" padding="50px" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Heading paddingBottom={majorScale(1)}>{headerText.current}</Heading>
          <Text>Would you like to submit it now?</Text>
          <Pane paddingTop={majorScale(3)} display="flex" justifyContent="space-around">
            <Button appearance="reject" onClick={() => deletePrevCheckin()}>
              No, delete it
            </Button>
            <Button appearance="submit" onClick={() => savePrevCheckin(prevPendingCheckin)}>
              Yes, save it
            </Button>
          </Pane>
        </Card>
      </Overlay>
    </ThemeProvider>
  );
}
