import React, { useState } from "react";
import { Card, Pane } from "evergreen-ui";
import { NavLink } from "react-router-dom";
import { getDateString } from "../helpers";
import { auth } from "../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

export default function NavBar() {
  const [loggedIn, loggedInSet] = useState(false);
  const date = getDateString();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      loggedInSet(true);
    } else {
      loggedInSet(false);
    }
  });

  const loggedInDisplay = (
    <>
      <Card className="navbar-item">
        <NavLink to={`/checkin/${date}`}>Checkin</NavLink>
      </Card>
      <Card className="navbar-item">
        <NavLink to="/habits">Habits</NavLink>
      </Card>
      <Card className="navbar-item">
        <NavLink to="/logout">Logout</NavLink>
      </Card>
    </>
  );
  const loggedOutDisplay = (
    <>
      <Card className="navbar-item">
        <NavLink to="/login">Login</NavLink>
      </Card>
    </>
  );

  let display = loggedIn ? loggedInDisplay : loggedOutDisplay;

  return (
    <Pane width="100%" borderBottom="1px solid grey" display="flex" justifyContent="space-between" paddingX="5vw" elevation={1} position="fixed" background="white">
      <Pane className="navbar-item">
        <NavLink to="/home">Accountable</NavLink>
      </Pane>
      <Pane display="flex" flexDirection="row">
        {display}
      </Pane>
    </Pane>
  );
}
