import { Card, Heading, Pane, majorScale } from "evergreen-ui";
import React from "react";

export default function Home() {
  return (
    <Pane position="absolute" top="50%" transform="translateY(-50%)" margin={majorScale(2)}>
      <Heading size={700}>Welcome to Accountable.</Heading>
      <Card maxWidth="600px" background="gray200" elevation={2} padding={majorScale(1)} marginX={majorScale(1)} marginY={majorScale(3)}>
        Accountable is a framework and a tool for living more truly to yourself. The underlying belief is simple - if each night you set your intentions for the next day, your
        goals will be more clearly in reach. Pair up with a partner - perhaps make a friendly bet - and social accountability & friendly competition kicks in to boost you with
        motivation and support.
      </Card>
      <Card fontWeight="bold" fontStyle="italic">
        Accountable is currently useable, but still very much under construction.
      </Card>
    </Pane>
  );
}
