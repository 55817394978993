// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAu5_7zBWohgMUSKdVkL3p0ZDBOVJysaBk",
  authDomain: "accountable-10c08.firebaseapp.com",
  projectId: "accountable-10c08",
  storageBucket: "accountable-10c08.appspot.com",
  messagingSenderId: "465704282087",
  appId: "1:465704282087:web:fbd740ea4b54bd6c2aff42",
  measurementId: "G-P2DPJLD72K",
  databaseURL: "https://accountable-10c08-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);

export default app;

// TODO: add firebase realtime DB (I mean I guess I could try/practice with firestore too, hmm.)
