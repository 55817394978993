import { cloneDeep } from "lodash";

// DATE FUNCTIONS
export function getDateString() {
  var date = new Date();
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}

export function getWeekdayMonthDateString(dateToFormat) {
  const tz = new Date();
  const offset = tz.getTimezoneOffset() * 60000;
  const timestamp = Date.parse(dateToFormat) + offset;
  const date = new Date(timestamp);
  const [weekday, month, daynumber] = date.toString().split(" ", 4);
  return `${weekday}, ${month} ${daynumber}`;
}

export function isYesterday(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const difference = d1.getTime() - d2.getTime();
  if (0 < difference && difference <= 86400000) return true;
  return false;
}

export function getPrevDayDateString(date) {
  const d = new Date(date);
  const y = new Date(d.getTime() - 86400000);
  return y.toISOString().split("T")[0];
}

// VALIDATION
export function validateHabits(habits) {
  const validatedHabits = cloneDeep(habits);
  for (let time in validatedHabits) {
    if (!validatedHabits[time]) delete validatedHabits[time];
  }
  return validatedHabits ?? {};
}

export function validateTomorrowGoals(tomorrowGoals) {
  if (!tomorrowGoals) return [];
  return tomorrowGoals.reduce((acc, cur) => {
    if (cur == "") return acc;
    return [...acc, cur];
  }, []);
}

export function isCheckinEmpty(goals, habits, checkin, tomorrowGoals) {
  if (goals && goals.length) return true;
}
