import { database } from "../firebaseConfig";
import { ref, child, get } from "firebase/database";

export async function getUserSettings(userId) {
  const snapshot = await get(child(ref(database), `users/${userId}`));
  try {
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
    }
  } catch (e) {
    console.error(e);
  }
}
