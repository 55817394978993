import NavBar from "../components/NavBar";
import { Pane } from "evergreen-ui";
import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <Pane>
      <NavBar />
      <Pane paddingTop="5vh"></Pane>
      <Outlet />
    </Pane>
  );
}
