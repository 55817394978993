import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();

  auth.signOut().then(() => {
    setTimeout(() => {
      navigate("/home");
    }, 2000);
  });
  return (
    <div id="logout-page">
      <h1>You have been successfully logged out!</h1>
    </div>
  );
}
